
import template from './ko-render-fields.html';

/**
 * Render fields knockout component class
 * @kind component
 * @class SampleComponent
 * @description Sample Component that shows the name value
 */
class ComponentViewModel
{
	constructor(params)
	{
		this.params = params;
		console.log('params:', params);
		this.fields = ko_helper.safe_observableArray(params.fields); // An array of fields to display
		this.inputs = ko_helper.safe_observable(params.inputs, {}); // An observable Object containing the input observables for each field.
		this.initial_values = ko_helper.safe_observable(params.initial_values, {}); // An observable Object containing the initial values.
		
		this._render_fields = ko.observableArray(); // Array used by this component
		
		this.fields.subscribe((fields) => {
			this.renderFields();
		});

		if (this.fields().length)
			this.renderFields();
	}

	renderFields()
	{
		const fields = this.fields();
		const newfields = [];
		const inputs = {};
		const ivals = this.initial_values();

		for (let field of (fields || []))
		{
			if (!field.name)
			{
				console.error(new Error('No "name" field for field'));
				continue;
			}
			
			field.fields_component = this;

			if (!field.$input || !ko.isObservable(field.$input))
				field.$input = ko.observable();

			if (ivals.hasOwnProperty(field.name))
				field.default_value = ivals[field.name];

			inputs[field.name] = field.$input;
			
			newfields.push(field);
		}
		this._render_fields(newfields);
		this.inputs(inputs);
	}

}

export default {
	name: 'ko-render-fields',
	viewModel: ComponentViewModel,
	module_type: 'ko',
	template: template
};
