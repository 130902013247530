
import template from './ko-render-field.html';

/**
 * Render fields knockout component class
 * @kind component
 * @class SampleComponent
 * @description Sample Component that shows the name value
 */
class ComponentViewModel
{
	constructor(params)
	{
		this.field = ko_helper.safe_observable(params);

		this.component_name = null;
		this.params = {};

		this.renderField();
	}

	renderField()
	{
		const field = this.field();

		this.component_name = this.getComponentForFieldType(field.type);

		if (!this.component_name)
		{
			console.warn(new Error(`No component for field with type=${field.type}`));
			return;
		}

		const params = Object.assign({ $input: null, label: null }, field);

		if (!field.label && field.name)
			params.label = field.name;

		if (!params.$input || !ko.isObservable(params.$input))
			params.$input = ko.observable();
		
		if (field.default_value)
			params.$input(field.default_value);

		this.params = params;
	}

	getComponentForFieldType(name)
	{
		const FieldType = Grape.registry.getItem('RenderFieldsFieldTypes', name);
		if (!FieldType)
		{
			console.error(new Error('Unknown render field type "' + name + '"'));
			return null;
		}
		return FieldType.component;
	}
}

export default {
	name: 'ko-render-field',
	viewModel: ComponentViewModel,
	module_type: 'ko',
	template: template
};
