
import template from './ps-lookup.html';

/**
 * KO component for building filter based on fields
 * @kind component
 * @class FieldsSelector
 */
class ComponentViewModel
{
	constructor(params, rootElement)
	{
		this.params = params;
		this.data = this.params.data;
		this.inputClasses = ko_helper.safe_observable(params.inputClasses || 'ps-input');
		this.value = ko_helper.safe_observable(params.value);
		this.messages = ko_helper.safe_observable(params.messages);
		this.warningMessages = ko_helper.safe_observable(params.warningMessages);
		this.valid = ko_helper.safe_observable(params.valid);
		this.enabled = ko_helper.safe_observable(params.enable);
		this.level = ko_helper.safe_observable(params.level);
		this.showOptionsMenu = ko_helper.safe_observable(params.showOptions);
		this.label = ko_helper.safe_observable(params.label);
		this.optionsSchema = ko_helper.safe_observable(params.optionsSchema);
		this.optionsTable = ko_helper.safe_observable(params.optionsTable);
		this.optionsField = ko_helper.safe_observable(params.optionsField);
		this.options = ko_helper.safe_observableArray(params.option||[]);
		this.optionsText = ko_helper.safe_observable(params.optionsText || 'text');
		this.optionsImage = ko_helper.safe_observable(params.optionsImage || 'image');
		this.matcher = params.matcher() || this._matcher;
		this.matcherDelay = ko_helper.safe_observable(params.matcherDelay || 250);
		this.noMatchesText = ko_helper.safe_observable(params.noMatchesText == undefined ? 'No Matches Found':params.noMatchesText);

		this.matcherTimeout;
		this.matchedOptions = ko.observableArray([]);
		this.matchedOptions.subscribe((newValue)=>{
			if (newValue.length == 0){
				let value = {};
				value[this.optionsText()] = this.searchText();
				this.value(value.description);

				if (this.showOptions() && !this.noMatchesText())
					this.showOptions(false);

			} else if (newValue.length > 0 && !this.showOptions() && document.activeElement === this.inputElement)
				this.showOptions(true && JSON.parse(this.showOptionsMenu()));
		})
		this.customOptions = ko.observableArray([]);
		this.searchText = ko.observable(this.value()?.[this.optionsText()] || '');
		this.searchText.subscribe(async (newValue)=>{
			this.value(newValue);
			clearTimeout(this.matcherTimeout);
			this.matcherTimeout = setTimeout(async()=>{
				let matchedOptions = await this.matcher(newValue, this);
				let self = this;

				if(matchedOptions.length === 0){

					if(self.level() === "error" && self.valid() === true && self.value() !== ""){

						self.messages().unshift("Entered value is not in pre-approved list of " + self.label().toLowerCase() + "s.");

						function removeDuplicates(arr) {
							return arr.filter((item,
								index) => arr.indexOf(item) === index);
						}
						let arr = removeDuplicates(self.messages());

						self.messages(arr);
						self.valid(false);

					}else if(self.level() === "warning" && self.valid() === true && self.value() !== ""){

						self.warningMessages().unshift("Entered value is not in pre-approved list of " + self.label().toLowerCase() + "s.");

						function removeDuplicates(arr) {
							return arr.filter((item,
								index) => arr.indexOf(item) === index);
						}
						let arr = removeDuplicates(self.warningMessages());

						self.warningMessages(arr);
						self.valid(false);

					}

				}

				matchedOptions.filter(function (entry) {
					if (entry.description !== self.value() && (self.value() !== "")) {

						if(self.level() === "error"){

							self.messages().unshift("Entered value is not in pre-approved list of " + self.label().toLowerCase() + "s.");

							function removeDuplicates(arr) {
								return arr.filter((item,
									index) => arr.indexOf(item) === index);
							}
							let arr = removeDuplicates(self.messages());

							self.messages(arr);
							self.valid(false);

						}else if(self.level() === "warning"){

							self.warningMessages().unshift("Entered value is not in pre-approved list of " + self.label().toLowerCase() + "s.");

							function removeDuplicates(arr) {
								return arr.filter((item,
									index) => arr.indexOf(item) === index);
							}
							let arr = removeDuplicates(self.warningMessages());

							self.warningMessages(arr);
							self.valid(false);

						}

					}
				});
				this.matchedOptions(matchedOptions);
			}, this.matcherDelay());
		});
		this.showOptions = ko.observable(false);
		this.inputElement = rootElement.querySelector('input');
		this.inputElement.addEventListener('focus', ()=>{
			if (this.matchedOptions().length == 0 && !this.noMatchesText())
				return;

			this.showOptions(true && JSON.parse(this.showOptionsMenu()));
		})
		this.inputElement.addEventListener('focusout', (event)=>{
			this.showOptions(false);
		})
		this.searchText.valueHasMutated();
	}

	_matcher(searchText, vm){
		const optionsText = vm.optionsText();
		let matchedOptions = [];

		for (let option of vm.options()){
			let regex = new RegExp(`.+${searchText}.+`, 'i');
			if (regex.test(option[optionsText]))
				matchedOptions.push(option);
		}

		return matchedOptions;
	}

	selectOption(option){
		this.searchText(option[this.optionsText()]);
		this.value(option.description);
		this.inputElement.blur();
	}

	addOption(optionText){
		this.customOptions(this.customOptions().concat({value:optionText, text:optionText}));
	}

	async fieldValueFormatter({name, data, component}){ //TODO optimize
		let value = '';
		let keys = name.split('.');
		if (keys.length === 1)
			value = data[keys[0]];
		else
			value = data[keys[0]][keys[1]];
		value ??= '';
		if (typeof value !== 'string')
			value = JSON.stringify(value);
		let rg = new RegExp(`(${component.searchText().replace(/[-[\]{}()*+?.,\\^$|]/g, "\\$&").trim().split(' ').join('|')})`, 'ig');
		return value.replace(rg, '<span style="background-color:yellow;color:black">$1</span>');
	}

}

export default {
	name: 'ps-lookup',
	viewModel: ComponentViewModel,
	provider: 'ko',
	template: template
};
