// CSS
import './css/platsoft-accordion.css';
import './css/platsoft-alerts.css';
import './css/platsoft-bg-colours.css';
import './css/platsoft-bs3-navbar.css';
import './css/platsoft-button.css';
import './css/platsoft-dashboard.css';
import './css/platsoft-dialog.css';
import './css/platsoft-dropdown.css';
import './css/platsoft-fg-colours.css';
import './css/platsoft-fieldset.css';
import './css/platsoft-forms.css';
import './css/platsoft-horisontal-tabs.css';
import './css/platsoft-label.css';
import './css/platsoft-layout.css';
import './css/platsoft-loader.css';
import './css/platsoft-modal.css';
import './css/platsoft-navbar.css';
import './css/platsoft-page-footers.css';
import './css/platsoft-page-headers.css';
import './css/platsoft-page-settings.css';
import './css/platsoft-page.css';
import './css/platsoft-pagination.css';
import './css/platsoft-panel.css';
import './css/platsoft-progress-bar.css';
import './css/platsoft-root.css';
import './css/platsoft-sidebar.css';
import './css/platsoft-staging-banner.css';
import './css/platsoft-switch.css';
import './css/platsoft-tab-pills.css';
import './css/platsoft-table.css';
import './css/platsoft-tags.css';
import './css/platsoft-text.css';
import './css/platsoft-treeview.css';

import './css/input/bs3-input.css';
import './css/input/platsoft-input.css';


/* Themes */
import './themes/platsoft-theme.css';
import './themes/theme-default-dark.css';
import './themes/theme-default-light.css';

import package_json from '../package.json';

/* Alerts */
import alerts from './PSUIAlerts.js';

import PSUIDialogs from './DialogProvider.js';

/* Import plugins */
import Plugin from './Plugin.js';
import ThemePlugin from './ThemePlugin.js';

import pages from './pages/index.js';
import dialogs from './dialogs/index.js';
import components from './components/index.js';
import ko_bindings from './lib/ko_bindings/index.js';

/* 3rd party libraries */
import './lib/3rdparty/index.js';

ko_bindings;
window.Grape.pages.registerPages(pages);
window.Grape.component.registerComponents(components);
window.Grape.dialog.registerDialogs(dialogs);

window.Grape.modules.push(package_json);

// Assign grape objects
window.Grape.dialog.providers['ps'] = PSUIDialogs;
window.Grape.plugins.register(Plugin);
window.Grape.plugins.register(ThemePlugin);

// Assign Grape.Alerts
window.Grape.alerts = alerts;

// Render Fields
import './render-fields/main.js';
