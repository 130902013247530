import template from './ps-tags.html';

class PsTagsVM
{
	constructor(params)
	{
		this.params = params;

		this.new_tag_value = ko.observable(); // partially typed tag
		this.current_tags = ko_helper.safe_observableArray(params.values, []); // actually selected values
		this.all_tags = ko_helper.safe_observableArray(params.tags, []); // list of tags to use
		this.new_tags = ko_helper.safe_observableArray(params.new_tags, []); // list of new tags to return.
		this.allow_custom = ko_helper.safe_observable(params.allow_custom, true); // allow adding custom elements

		console.info('params', this.current_tags());

		this.new_tags(this.getAdditionalTags());
		this.current_tags.subscribe(() => {
			this.new_tags(this.getAdditionalTags());
		});
	}

	getAdditionalTags() {
		const oldTags = new Set(this.all_tags() ?? []);
		const newTags = new Set(this.current_tags() ?? []);

		return Array.from(newTags.difference(oldTags));
	}

	addTag (model, event)
	{
		const tags = this.current_tags() ?? [];
		const value = this.new_tag_value();

		if (event.keyCode !== 13 && (event.type !== 'blur'))
			return true;

		if (value)
		{
			if (!tags.includes(value))
			{
				tags.push(this.new_tag_value());
				this.current_tags(tags);
			}
			this.new_tag_value('');
		}

		return true;
	}

	removeTag (value)
	{
		const tags = this.current_tags() ?? [];
		tags.splice(tags.indexOf(value), 1);
		this.current_tags(tags);
	}

	itemClick (value)
	{
		const tags = this.current_tags() ?? [];

		if (tags.includes(value))
			tags.splice(tags.indexOf(value), 1);
		else
			tags.push(value);

		this.current_tags(tags);
	}

	selectedItem (value)
	{
		return this.current_tags().some(selected_item => selected_item === value);
	}
}

export default {
	name: 'ps-tags',
	viewModel: PsTagsVM,
	module_type: 'ko',
	template: template
};
