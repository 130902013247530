/**
 * @kind ko binding handler
 * @name dataToggle
 * @description A Knockout.js binding handler that handles various toggle behaviors:
 * 1) dropdown (default)
 * 2) accordion
 * 3) nav-pill
 */
ko.bindingHandlers.dataToggle = {
	init: function (element, valueAccessor) {
		let type = ko.unwrap(valueAccessor()) || {};
		
		// TODO remove this - it is in here to handle legacy code for dropdowns (data-bind="dataToggle: {}")
		if (typeof type === 'object')
			type = 'dropdown';

		if (type === 'dropdown')
		{
			let button = element.querySelector('button');
			let dropdown_menu_ul = element.querySelector('ul');

			if (!button || !dropdown_menu_ul)
				return;

			dropdown_menu_ul.style.display = 'none';

			function toggle_dropdown() {
				dropdown_menu_ul.style.display = (
					dropdown_menu_ul.style.display === 'none' ? 'block' : 'none'
				);
			}

			function close_dropdown(event) {
				if (!element.contains(event.target))
					dropdown_menu_ul.style.display = 'none';
			}

			button.addEventListener('click', function (event) {
				event.stopPropagation();
				toggle_dropdown();
			});

			document.addEventListener('click', close_dropdown);
		}
		else if (type === 'accordion')
		{
			let articles = element.querySelectorAll('article');

			if (!articles.length)
				return;

			articles.forEach((article) => {
				let label = article.querySelector('label');
				let section = article.querySelector('section');

				if (!label || !section)
					return;

				// If the article is already "active"
				let is_active = article.classList.contains('active');
				section.style.display = is_active ? 'block' : 'none';
				if (is_active)
					label.classList.add('selected');

				// Toggle open/close on label click
				label.addEventListener('click', () => {
					let is_open = article.classList.contains('active');

					if (is_open)
					{
						article.classList.remove('active');
						label.classList.remove('selected');
						section.style.display = 'none';
					}
					else
					{
						article.classList.add('active');
						label.classList.add('selected');
						section.style.display = 'block';
					}
				});
			});
		}
		else if (type === 'nav-pill')
		{
			let list_items = element.querySelectorAll('li');
			if (!list_items.length)
				return;
		
			let container = element.parentElement || document;
			let tab_contents = container.querySelectorAll('[data-tab-content]');

			let tabs = Array.from(list_items).map(li => li.getAttribute('data-tab')).join('|');
			let storage_key = "nav-pill-active-tab-" + tabs;
		
			function activate_tab (tab)
			{
				list_items.forEach((li) => {
					if (li.getAttribute('data-tab') === tab)
						li.classList.add('active');
					else
						li.classList.remove('active');
				});

				tab_contents.forEach((content) => { content.style.display = content.getAttribute('data-tab-content') === tab ? 'block' : 'none'; });
			}
		
			// Check localStorage for stored tab
			let stored_tab = localStorage.getItem(storage_key);
			if (stored_tab)
			{
				let found = false;
				list_items.forEach((li) => {
					if (li.getAttribute('data-tab') === stored_tab)
						found = true;
				});

				if (!found)
					stored_tab = null;
			}
		
			// If no stored tab, try to get li with "active" class
			if (!stored_tab)
			{
				let active_li = element.querySelector('li.active');
				if (active_li)
					stored_tab = active_li.getAttribute('data-tab');
			}
		
			// If still not set, default to first li
			if (!stored_tab && list_items.length)
				stored_tab = list_items[0].getAttribute('data-tab');
		
			// Activate chosen tab
			if (stored_tab)
				activate_tab(stored_tab);
		
			// Attach click events
			list_items.forEach((li) => {
				li.addEventListener('click', () => {
					let tab = li.getAttribute('data-tab');
					if (tab)
					{
						activate_tab(tab);
						localStorage.setItem(storage_key, tab);
					}
				});
			});
		}
	}
};

export default 'bindings';
