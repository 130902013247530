
import template from './ps-select.html';

/**
 * KO component advanced version of a <select>
 * @kind component
 * @class FieldsSelector
 */
class PSSelectViewModel
{
	constructor(params, rootElement)
	{
		this.params = params;
		this.data = this.params.data;
		this.inputClasses = ko_helper.safe_observable(params.inputClasses || 'ps-input');
		this.value = ko_helper.safe_observable(params.value);
		this.options = ko_helper.safe_observableArray(params.options || param.option || []);
		this.multiple = ko_helper.safe_observable(params.multiple || false);
		this.enableSearch = ko_helper.safe_observable(params.enableSearch || false);
		this.selectedOptions = ko_helper.safe_observableArray(params.selectedOptions || []);

		this.optionsText = ko_helper.safe_observable(params.optionsText || null);
		this.optionsValue = ko_helper.safe_observable(params.optionsValue || null);
		this.optionsImage = ko_helper.safe_observable(params.optionsImage || null);
		this.matcher = params.matcher || this._matcher;
		this.matcherDelay = ko_helper.safe_observable(params.matcherDelay || 250);
		this.noMatchesText = ko_helper.safe_observable(params.noMatchesText == undefined ? 'No Matches Found':params.noMatchesText);
		this.optionsFormatter = params.optionsFormatter || this.fieldValueFormatter;
		this.placeholder = ko_helper.safe_observable(params.placeholder || '');
		
		this.isOpen = ko.observable(false);
		this.valueOnDisplay = ko.observable('');

		this.matcherTimeout;
		this.visibleOptions = ko.observableArray([]);
		this.customOptions = ko.observableArray([]);
		this.searchText = ko.observable(this.value()?.[this.optionsText()] || '');

		if (this.enableSearch() === true)
		{
			this.searchText.subscribe(async (newValue)=>{
				clearTimeout(this.matcherTimeout);
				this.matcherTimeout = setTimeout(async()=>{
					let matchedOptions = await this.matcher(newValue, this);

					this.visibleOptions(matchedOptions.concat(this.selectedOptions()));
				}, this.matcherDelay());
			});
		}
		else
		{
			this.visibleOptions(this.options());
			this.options.subscribe((nv) => {
				this.visibleOptions(nv);
			});
		}

		this.inputElement = rootElement.querySelector('input');
		this.inputElement.addEventListener('focus', ()=>{
			if (this.visibleOptions().length == 0 && !this.noMatchesText())
				return;

			this.isOpen(true);
		})
		this.inputElement.addEventListener('focusout', (event)=>{
			this.isOpen(false);
		})
		this.value.subscribe((newValue)=>{
			this.valueOnDisplay(newValue?.[this.optionsText()])
		});
		this.valueOnDisplay.valueHasMutated();

		if (this.enableSearch())
			this.searchText.valueHasMutated();

		this.selectedOptions.subscribe((nv) => {
			let lst = [];
			for (let n of nv || [])
			{
				lst.push(this.optionsText() ? n[this.optionsText()] : n);
			}
			this.valueOnDisplay(lst.join(', '));
		});
	}

	_matcher(searchText, vm){
		const optionsText = vm.optionsText();
		let matchedOptions = [];

		for (let option of vm.options()){
			let regex = new RegExp(`.+${searchText}.+`, 'i');
			if (regex.test(option[optionsText]))
				matchedOptions.push(option);
		}

		return matchedOptions;
	}

	/**
	 * Click on an option
	 */
	selectOption(option) {
		if (this.multiple() == false)
		{
			this.value(this.optionsValue() ? option[this.optionsValue()] : option);
			this.selectedOptions([option]);
			this.inputElement.blur();
			this.searchText(option?.[this.optionsText()]);
		}
		else
		{
			const list = this.selectedOptions();
			if (list.indexOf(option) > -1)
			{
				list.splice(list.indexOf(option), 1);
				this.selectedOptions(list);
			}
			else
			{
				list.push(option);
				this.selectedOptions(list);
			}
		}
	}

	addOption(optionText) {
		this.customOptions(this.customOptions().concat({value:optionText, text:optionText}));
	}

	async fieldValueFormatter({name, data, component}){ //TODO optimize
		let value = '';
		let keys = name.split('.');
		if (keys.length === 1)
			value = data[keys[0]];
		else
			value = data[keys[0]][keys[1]];
		value ??= '';
		if (typeof value !== 'string')
			value = JSON.stringify(value);
		if (!component.searchText())
			return value;
		let rg = new RegExp(`(${component.searchText().replace(/[-[\]{}()*+?.,\\^$|]/g, "\\$&").trim().split(' ').join('|')})`, 'ig');
		return value.replace(rg, '<span style="background-color:yellow;color:black">$1</span>');
	}

}

export default {
	name: 'ps-select',
	viewModel: PSSelectViewModel,
	provider: 'ko',
	template: template
};
